$break-large: 1170px;
$break-tablet: 768px;
$break-mobile: 480px;

.card-item {
  position: relative;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  max-width: 908px;

  .image {
    position: absolute;
    left: 10px;
    top: 35px;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0px 6px 18px rgba(0,0,0, 0.3);

    @media screen and (max-width: $break-mobile){
      bottom: auto;
    }

    .img {
      box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.1);
      width: 350px;
      height: 233px;
      background-size: cover;
      background-position: center center;

      @media screen and (max-width: $break-mobile){
        box-shadow: none;
      }
    }
    @media screen and (max-width: $break-large){
      max-width: 255px;
    }
    @media screen and (max-width: $break-tablet){
      width: 100%;
      max-width: 100%;
      top: 0;
      left: 0;
      border-radius: 3px;
      box-shadow: none;

      .img {
        width: 100%;
      }
    }
  }

  .content {
    background-color: #FFFFFF;
    box-shadow: 0 2px 12px 1px rgba(173, 173, 173, 0.2);
    border-radius: 3px;
    margin-top: 8%;
    margin-left: 6%;
    float: right;
    position: relative;
    width: 95%;
    min-height: 240px;

    @media screen and (max-width: $break-tablet) {
      float: left;
      position: relative;
      min-height: auto;
      margin-top: 234px;
      margin-left: 0px;
      width: 100%;
      padding-bottom: 16px;
    }

    &.margin {
      margin-top: 3%;
    }
    .margin-left {
      margin-left: 300px;
    }

    .icon {
      float: right;
      margin-right: 40px;

      .bookmark, .heart, .comment {
        float: right;
        font-size: 12px;
        color: #bbb;
      }
      .bookmark, .comment {
        margin-left: 10px;
      }

      @media screen and (max-width: $break-mobile) {
        margin-right: 0;
      }
    }

    .author {
      position: absolute;
      bottom: 10px;
      left: 3%;
      font-size: 1em;
      clear: both;
      width: 100%;

      @media screen and (max-width: $break-tablet){
        width: 100%;
        float: left;
        left: 0px;

        position: relative;
        padding: 20px 20px 0px;
        width: 100%;
      }
      a {
        opacity: 0.7;
      }
    }
  }

  .content-right {
    float: left;
    margin-left: 350px;

    @media screen and (max-width: $break-large){
      margin-left: 255px;
    }
    @media screen and (max-width: $break-tablet){
      width: 100%;
      float: left;
      margin-left: 0;
      padding-left: 20px;
      padding-right: 20px;
    }

    h3 {
      font-size: 1.3em;
      margin: 16px 0 10px;
      @media screen and (max-width: $break-mobile){
        //padding: 0 24px;
      }
    }
    .intro {
      font-size: 1em;
      margin: 20px 0;
      text-align: justify;
      padding-right: 26px;
      min-height: 116px;

      h3 {
        font-size: 1em;
      }

      @media screen and (max-width: $break-mobile){
        float: left;
        width: 100%;
        //padding-left: 24px;
      }
    }
  }

  .content-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 20px;

    .shop-logo {
      margin-right: 20px;

      @media screen and (max-width: $break-mobile) {
        max-height: 50px;
        width: auto;
        display: block;
      }
    }

    .products {
      a {
        color: #333333;
      }
    }
  }
}
